
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

li a.with-drop:after {
  line-height: 21px;
  position: absolute;
  top: 50%;
  right: 5px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  -webkit-transition-duration: .5s;
  -moz-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}
li.sfHover > a.with-drop:after {
  -webkit-transition-duration: .5s;
  -moz-transition-duration: .5s;
  -o-transition-duration: .5s;
  transition-duration: .5s;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

/*Dropzone*/
.dropzone-over {
  border: 1px solid red !important;
}

.dropzone-area {
  width: 100%;
  height: 100px;
  position: relative;
  border: 2px dashed #CBCBCB;
}
.dropzone-area:hover {
  border: 2px dashed #2E94C4;
}
.dropzone-area:hover .dropzone-title {
  color: #1975A0;
}

.dropzone-area input {
  position: absolute;
  cursor: pointer;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dropzone-text {
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  width: 100%;
}
.dropzone-text span {
  display: block;
  font-family: Arial, Helvetica;
  line-height: 1.9;
}

.dropzone-title {
  font-size: 13px;
  color: #787878;
  letter-spacing: 0.4px;
}

.dropzone-info {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #A8A8A8;
  letter-spacing: 0.4px;
}

.dropzone-button {
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.dropzone-preview {
  width: 80%;
  position: relative;
}
.dropzone-preview:hover .dropzone-button {
  display: block;
}
.dropzone-preview img {
  display: block;
  height: auto;
  max-width: 100%;
}
.mw-loading{
  z-index: 5555;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
}
.popover .popover-content{
  margin-right: 35px;
}
.timeline-box .tl-row .tl-item .popover{
  display: contents !important;
}
.popover.left > .arrow{
  right: 40px;
}
.birthdayfield{
  display: none;
}
.map_letters .content-box-header.bg-primary a.enforcement-email-template-modal-link{
  display: contents;
  color: #000;
  font-size: 0.8rem;
}